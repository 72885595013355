<template>
  <base-material-card
    color="success"
    icon="mdi-account-cash"
    title="Premium & Fees"
  >
    <div
      class="status-badge"
      style="padding: 5px;"
    >
      <v-speed-dial
        direction="bottom"
      >
        <template v-slot:activator>
          <v-btn
            fab
            small
            :loading="acting"
            color="accent"
          >
            <v-icon>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip
          v-for="(action, j) in actions"
          :key="j"
          left
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              :color="action.color"
              fab
              small
              @click="trigger(action.action)"
              v-on="on"
            >
              <v-icon v-text="action.icon" />
            </v-btn>
          </template>

          <span>{{ action.what }}</span>
        </v-tooltip>
      </v-speed-dial>
    </div>

    <v-card-text>
      <v-text-field
        v-model="policyCost.base_premium"
        label="Base Premium"
        prefix="$"
      />
      <v-row
        justify="end"
        class="mb-2"
      >
        <v-btn
          small
          color="success"
          :loading="updating"
          @click="updateBasePremium"
        >
          <v-icon left>
            mdi-update
          </v-icon>
          Update
        </v-btn>
      </v-row>
      <p
        class="d-flex justify-space-between ml-5"
      >
        <span>
          <v-icon class="mr-3">mdi-plus-circle</v-icon>
          <span>Carrier Fees</span>
        </span>
        <span>$ {{ policyCost.carrier_fees || '-' }}</span>
      </p>
      <p
        class="d-flex justify-space-between ml-5"
      >
        <span>
          <v-icon class="mr-3">mdi-plus-circle</v-icon>
          <span>Endorsements</span>
        </span>
        <span>$ {{ policyCost.endorsements || '-' }}</span>
      </p>
      <p
        class="d-flex justify-space-between"
      >
        <span>
          <v-icon
            color="primary"
            class="mr-2"
          >
            mdi-equal-box
          </v-icon>
          <span class="font-weight-bold primary--text">Policy Premium</span>
        </span>
        <span class="primary--text">$ {{ policyCost.policy_premium || '-' }}</span>
      </p>
      <p
        class="d-flex justify-space-between ml-5"
      >
        <span>
          <v-icon class="mr-3">mdi-plus-circle</v-icon>
          <span>Agency Fees</span>
        </span>
        <span>$ {{ policyCost.agency_fees || '-' }}</span>
      </p>
      <p
        class="d-flex justify-space-between"
      >
        <span>
          <v-icon
            color="success"
            class="mr-2"
          >
            mdi-equal-box
          </v-icon>
          <span class="font-weight-bold success--text">Grand Total</span>
        </span>
        <span class="success--text">$ {{ policyCost.policy_premium || '-' }}</span>
      </p>
    </v-card-text>
  </base-material-card>
</template>

<script>
  import { Api } from '@/apis'

  export default {
    props: {
      policyCost: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      updating: false,
      acting: false,
      actions: [
        { icon: 'mdi-currency-usd', color: 'success', what: 'Change Base Premium', action: 'change_base_premium' },
        { icon: 'mdi-bank-plus', color: 'secondary', what: 'Add Carrier Fee', action: 'add_carrier_fee' },
        { icon: 'mdi-checkbox-marked-circle', color: 'warning', what: 'Endorse', action: 'endorse' },
        { icon: 'mdi-cart-plus', color: 'primary', what: 'Add Agency Fee', action: 'add_agency_fee' },
      ],
    }),

    methods: {
      async updateBasePremium () {
        this.updating = true
        await new Api().put({
          url: 'policies/policy/' + this.$route.params.id,
          request: { base_premium: this.policyCost.base_premium },
        })
        this.updating = false
        this.$emit('update-policy')
      },

      trigger (action) {},
    },
  }
</script>
